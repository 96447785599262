<template>
  <div id="bank-card">
    <van-nav-bar title="银行卡" left-arrow @click-left="goLastPage" />
    <div style="margin-top: 2rem" v-if="bank_card_arr.length > 0">
      <van-swipe-cell v-for="(item,index) in bank_card_arr" :key="index">
        <div class="haveBandCard" v-if="bank_card_arr.length > 0">
          <BankCard :bankCardObj="item" readOnly />
        </div>
        <template #right>
          <van-button square text="解绑" type="danger" class="delete-button" @click="beforeClose(item)" />
        </template>
      </van-swipe-cell>
    </div>
    <div class="flexCenter noBandCard" v-else>
      <svg class="icon cardNull" aria-hidden="true">
        <use xlink:href="#icon-yinhangqia-1"></use>
      </svg>
      <p style="font-size: 1.4rem">您还没有绑定银行卡，去绑定吧</p>
    </div>

    <div class="bandCard flexCenter" @click="bandCardShow = true">
      添加新卡
      <svg class="icon iconReset" aria-hidden="true">
        <use xlink:href="#icon-add"></use>
      </svg>
    </div>
    <!-- 添加新卡 -->
    <van-popup v-model="bandCardShow" position="bottom" :style="{ height: '100%' }" visible-item-count="2" duration="0" :overlay="false" style="background: #f1f0f5">
      <van-nav-bar title="添加银行卡" @click-left="bandCardShow = false" left-text="返回" />
      <div style="margin: 1rem 0">
        <van-field v-model="bank_card_number" type="number" label="银行卡号" :error-message="error_message" @blur="amountChange" />
        <van-field v-model="bank_name" label="银行卡名" readonly />
      </div>
      <div class="fixedBottom">
        <van-button color="linear-gradient(to right, #ff6034, #ee0a24)" block @click="submitBankCard">
          确认添加
        </van-button>
      </div>
    </van-popup>
  </div>
</template>
<script>
import BankCard from "@/components/Mine/wallet/BankCard.vue";
export default {
  data() {
    return {
      bandCardShow: false,
      bank_card_number: "",
      bank_name: "",
      bank_number: "",
      error_message: "",
      bank_card_arr: [],
    };
  },
  components: {
    BankCard,
  },
  created() {
    this.getBandcardList();
  },
  methods: {
    amountChange() {
      if (this.bank_card_number.length > 5) {
        this.error_message = "请务必绑定本人储蓄银行卡哦，否则不能提现";
      }
      this.$http
        .get(`/api/v2/worker/bank_card/${this.bank_card_number}`)
        .then((res) => {
          this.bank_name = res.data.bankName;
          console.log(res);
        });
    },
    // 加载银行卡列表
    getBandcardList() {
      this.$http.get("/api/v2/worker/bank_card").then((res) => {
        console.log("银行卡列表", res);
        this.bank_card_arr = res.data.cards;
      });
    },
    // 解绑列表
    beforeClose(item) {
      this.$dialog
        .confirm({
          message: "确认解绑？",
        })
        .then((res) => {
          this.$http
            .delete(`/api/v2/worker/bank_card/${item.id}`)
            .then((res) => {
              this.$toast.success(res.data.message);
              this.getBandcardList();
            });
        })
        .catch((err) => {
          this.$toast.fail(err.error);
          // on cancel
        });
    },
    submitBankCard() {
      if (
        this.bank_card_number.length < 16 ||
        this.bank_card_number.length > 19
      ) {
        this.$toast("请输入正确银行卡号!");
      } else {
        this.$http
          .post("/api/v2/worker/bank_card/", {
            bank_card_number: this.bank_card_number,
            bank_name: this.bank_name,
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$toast.success("添加成功");
              this.bandCardShow = false;
              this.getBandcardList();
            }
          })
          .catch((err) => {
             this.$toast.fail(err.error);
          });
      }
    },
  },
};
</script>

<style lang="less">
#bank-card {
  .cardNull {
    width: 10rem;
    height: 10rem;
  }
  .noBandCard {
    flex-direction: column;
    padding: 2rem 0;
  }
  .haveBandCard {
    margin: 0 1rem;
  }

  .bandCard {
    width: 90%;
    background: #fff;
    padding: 1rem 0;
    margin: 1rem auto;
    border-radius: 200px;
    font-size: 1.4rem;
  }
  .iconReset {
    margin-left: 1rem;
    width: 2rem;
    height: 2rem;
  }
  .goods-card {
    margin: 0;
    background-color: #fff;
  }

  .delete-button {
    height: 100%;
    background: red;
    border: 1px solid red;
  }
}
</style>